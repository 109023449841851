<template>
  <div>
    <v-expansion-panels v-model="panel">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="v-card__title">{{ translate('Transfer Info') }}</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div style="padding: 10px">
            <div class="v-card__text">
              <v-form ref="first_form" lazy-validation class="v-form multi-col-validation">
                <div class="row">
                  <div class="col-md-6 col-12">
                    <label class="text-center text-uppercase text--primary bold">{{ translate('Date') }}</label>

                    <div class="
                        v-input v-input--hide-details v-input--dense
                        theme--light
                        v-text-field
                        v-text-field--is-booted
                        v-text-field--enclosed
                        v-text-field--outlined
                        v-text-field--placeholder
                      ">
                      <div class="v-input__control">
                        <div class="v-input__slot">
                          <fieldset aria-hidden="true">
                            <legend style="width: 0px"><span class="notranslate">​</span></legend>
                          </fieldset>

                          <v-datetime-picker class="outlined" v-model="date" @input="menu2"> </v-datetime-picker>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <label class="text-center text-uppercase text--primary bold">{{
                      translate('From Magazine')
                    }}</label>

                    <v-select :rules="[v => !!v || 'Item is required!']" outlined dense
                      @input="name => from_magazine_selected(name)" :items="stocks.map(a => a.magazine_name)"></v-select>
                  </div>
                  <div class="col-md-6 col-12">
                    <label class="text-center text-uppercase text--primary bold">{{ translate('To Magazine') }}</label>

                    <v-select :rules="[v => !!v || 'Item is required!']" outlined dense
                      @input="name => to_magazine_selected(name)" :items="magazines.map(a => a.name)"></v-select>
                  </div>
                </div>
              </v-form>
            </div>

            <v-card class="mx-auto mt-10" outlined v-if="selected_magazine != null">
              <div data-v-6fcca708="" class="d-flex justify-space-between flex-wrap pt-12">
                <div data-v-6fcca708="" class="me-2 mb-2">
                  <div data-v-6fcca708="" class="v-card__title pt-0 px-0">{{ translate('Stocks') }}</div>
                </div>
                <button data-v-6fcca708="" v-on:click="
                  selected_stocks.push({
                    id: Math.random() * 10000,
                    product_id: stocks[0].product_id,
                    amount: 0,
                  })
                  " type="button" class="v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default primary">
                  <span class="v-btn__content">{{ translate('add') }}</span>
                </button>
              </div>
              <v-form ref="third_form" lazy-validation>
                <v-simple-table height="auto" max-height="500" fixed-header>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center text-uppercase">Image</th>

                        <th class="text-center text-uppercase">ID</th>

                        <th class="text-center text-uppercase">{{ translate('Product') }}</th>

                        <th class="text-center text-uppercase">
                          {{
                            translate('Amount')
                          }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </th>

                        <th class="text-center text-uppercase">{{ translate('Actions') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="stock in selected_stocks" :key="stock.id">
                        <td class="text-center">
                          <div style="
                              overflow: visible;
                              position: relative;
                              min-width: 110px;
                              max-width: 110px;
                              min-height: 110px;
                              max-height: 110px;
                              margin: 5px;
                              padding: 5px;
                              border-style: dashed;
                            ">
                            <a :href="stock.image_url" target="_blank"><v-img style="height: 95px; width: 100%"
                                :src="stock.image_url"></v-img></a>
                          </div>
                        </td>
                        <td class="text-center">
                          <v-text-field @input="product_id_changed(stock)" v-model="stock['product_id']" :label="'ID'"
                            clear-icon="shrink" outlined type="number" dense></v-text-field>
                        </td>
                        <td class="text-center" style="padding: 10px">
                          <v-select :rules="[v => !!v || 'Item is required']" dense v-model="stock['product_name']"
                            class="shrink" outlined @input="id => product_selected(stock, id)"
                            :items="getProducts"></v-select>
                        </td>

                        <td class="text-center" style="padding: 10px">
                          <v-text-field :label="'Amount' + '(available: ' + stock['product_amount'] + ')'" :key="key"
                            v-model="stock['amount']" :suffix="stock.measurement_name" outlined dense :rules="[
                              v => !!v || 'Item is required!',
                              v =>
                                v <= parseInt(stock['product_amount']) ||
                                'cant be higher than ' + stock['product_amount'] + '',
                            ]" :min="1" :max="stocks.find(x => x.product_id === stock.product_id).amount"
                            type="number"></v-text-field>
                        </td>

                        <td class="text-center">
                          <div v-on:click="selected_stocks = selected_stocks.filter(item => item !== stock)"
                            class="v-avatar elevation-1 rounded error" style="height: 30px; min-width: 30px; width: 30px">
                            <span aria-hidden="true" class="v-icon notranslate theme--dark white--text"
                              style="font-size: 25px; height: 25px; width: 25px"><svg xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24" role="img" aria-hidden="true" class="v-icon__svg"
                                style="font-size: 25px; height: 25px; width: 25px">
                                <path :d="icons.mdiCloseCircleOutline"></path>
                              </svg></span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-form>
            </v-card>
          </div>

          <v-btn color="success" style="margin: 10px" v-on:click="submit"> {{ translate('Submit') }} </v-btn>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>



<script>
import { ref } from '@vue/composition-api'
import axios from 'axios'
import { mdiPlus, mdiCloseCircleOutline } from '@mdi/js'
import { LMap, LTileLayer, LMarker, LTooltip, LPolyline, LIcon } from 'vue2-leaflet'
import { Icon } from 'leaflet'
import * as moment from 'moment'
import { intToHex } from 'vuetify/lib/util/colorUtils'
import { getCurrency, getMagazines, getStocks, insertNeworder, insertNewTransfer } from '@/ApiManager'

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})
export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
    LPolyline,
    LIcon,
  },
  methods: {
    mapclick(data) {
      this.markerLatLng = [data.latlng['lat'], data.latlng['lng']]
    },

    turnStockToString(stock) {
      return `${stock.id}${stock.amount}${stock.product.id}${stock.quantity_price}${stock.tax}${stock.discount}`
    },
    calculateTotal(stock) {
      var price = 0
      //get total price
      price = stock.amount * stock.quantity_price
      //calculate discount
      price = price - (price * stock.discount) / 100

      //calculate tax
      price = price + (price * stock.tax) / 100
      return price
    },
    product_id_changed(stock) {
      for (var i = 0; i < this.stocks.length; i++) {
        if (
          this.stocks[i].product_id == stock['product_id'] &&
          this.selected_magazine.name == this.stocks[i].magazine_name
        ) {
          stock['product_name'] = this.stocks[i].product_name
          stock['product_id'] = this.stocks[i].product_id
          stock['measurement_name'] = this.stocks[i].measurement_name
          stock['product_amount'] = this.stocks[i].amount
          stock['amount'] = 1
          stock['magazine_id'] = this.stocks[i].magazine_id
          stock['product_buy_price'] = this.stocks[i].buy_price
          stock['quantity_price'] = this.stocks[i].sell_price
          this.key += 1
          return
        }
      }
    },
    product_selected(stock, name) {
      for (var i = 0; i < this.stocks.length; i++) {
        if (this.stocks[i].product_name == name && this.selected_magazine.name == this.stocks[i].magazine_name) {
          console.log(stock)
          stock['product_id'] = this.stocks[i].product_id
          stock['measurement_name'] = this.stocks[i].measurement_name
          stock['product_amount'] = this.stocks[i].amount
          stock['amount'] = 1
          stock['magazine_id'] = this.stocks[i].magazine_id
          stock['product_buy_price'] = this.stocks[i].buy_price
          stock['quantity_price'] = this.stocks[i].sell_price
          stock['image_url'] = this.stocks[i].image_url

          this.key += 1
          return
        }
      }
    },
    from_magazine_selected(magazine_name) {
      for (var i = 0; i < this.stocks.length; i++) {
        if (this.stocks[i].magazine_name == magazine_name) {
          this.selected_stocks = []
          this.selected_magazine = {
            name: this.stocks[i].magazine_name,
            id: this.stocks[i].magazine_id,
            lat: this.stocks[i].magazine_lat,
            lng: this.stocks[i].magazine_lng,
          }
          console.log(this.stocks[i])
          return
        }
      }
    },
    to_magazine_selected(magazine_name) {
      for (var i = 0; i < this.magazines.length; i++) {
        if (this.magazines[i].name == magazine_name) {
          this.to_selected_magazine = {
            name: this.magazines[i].name,
            id: this.magazines[i].id,
            lat: this.magazines[i].lat,
            lng: this.magazines[i].lng,
          }
          return
        }
      }
    },
    submit: function (_) {
      if (this.$refs['first_form'].validate() == false) {
        return
      }
      if (this.selected_stocks.length == 0) {
        return
      }
      this.$store.state.loading = true

      var date = `${this.date.getFullYear()}/${('0' + (this.date.getMonth() + 1)).slice(-2)}/${(
        '0' + this.date.getDate()
      ).slice(-2)} ${this.date.getHours()}:${this.date.getMinutes()}:${this.date.getSeconds()}`
      console.log(this.selected_magazine, this.to_selected_magazine)
      insertNewTransfer(this.selected_stocks, this.selected_magazine.id, this.to_selected_magazine.id, date).then(
        response => {
          this.$router.push({ name: 'transfers' })
          this.$store.state.loading = true
        },
      )
    },
  },
  computed: {
    delivery_fee_visible() {
      if (this.textToSalesPost(this.sales_post) == 'client_picks_up') {
        return false
      } else {
        return true
      }
    },
    getProducts() {
      return this.stocks.filter(item => item.magazine_id == this.selected_magazine.id).map(a => a.product_name)
    },
    dynamicSize() {
      return [this.iconSize * 0.5, this.iconSize * 0.5]
    },
    dynamicAnchor() {
      return [this.iconSize * 0.5, this.iconSize * 0.5]
    },
  },
  async created() {
    this.$store.state.loading = true
    getMagazines().then(response => {
      this.magazines = response
    })
    getStocks().then(response => {
      console.log(response)
      this.stocks = response
    })
    getCurrency().then(response => {
      this.currency = response['currency']
    })
    try {
      var coordinates = await this.$getLocation()
    } catch (c) {
      var coordinates = { lat: 41.3092303, lng: 19.945797 }
    }
    this.center = [coordinates['lat'], coordinates['lng']]
    this.markerLatLng = [coordinates['lat'], coordinates['lng']]
    this.leaflet_key += 1
    this.$store.state.loading = false
  },
  data() {
    return {
      sales_post: 'Courier',
      delivery_fee: 0,
      panel: 0,
      emptyRule: [v => !!v || "Can't be empty!"],
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 10,
      center: [0, 0],
      markerLatLng: [0, 0],
      leaflet_key: 0,
      currency: '',
      date: new Date(),
      menu2: false,
      stocks: [],
      selected_stocks: [],

      key: 0,
      selected_magazine: null,
      to_selected_magazine: null,

      client_name: '',
      email: '',
      phone_number: '',
      address: '',
      magazines: [],
    }
  },
  setup() {
    const product_name = ref('')
    const leaflet_key = ref('')
    const inventory_address = ref('')
    const sell_price = ref('')

    return {
      icons: {
        mdiPlus,
        mdiCloseCircleOutline,
      },
      product_name,
      leaflet_key,
      inventory_address,
      sell_price,
    }
  },
}
</script>
<style scoped>
@import url('https://unpkg.com/leaflet@1.6.0/dist/leaflet.css');

.bold {
  color: black;
  font-family: sans-serif;
  font-weight: 700;
}

.component-container {
  display: table;

  @media (pointer: none),
  (pointer: coarse) {
    width: calc(100% - (50%));
  }
}

.component-row {
  display: table-row;
}

.component-row div {
  position: relative;
  display: table-cell;
}

.component-row div:nth-child(2) {
  left: 20px;
}

.component-row div:nth-child(3) {
  left: 40px;
}
</style>